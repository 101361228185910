@import './colors';
@import 'mixins';

/**
 * Redesign styles
 *
 * these specifically target various mat- components that we want to add some styles to
 */
$input-border: 1px solid getColor('borderColor');
$input-border-radius: 4px;
$input-margin-top: 0.9em;

$form-field-padding-left-right: 8px;
$form-field-padding-top-bottom: 10px;

$ng-select-bottom-margin: 0.4375em;

@mixin matLabel() {
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 0px;
	display: block;
}

@mixin fixMatFormFieldWidth($width) {
	.mat-mdc-form-field-infix {
		max-width: $width;
		min-width: $width !important;
	}
}

// Apply these styles globally to our application to tweak material UI styles
button:hover {
	@include simulateMatFocused();
}

.mat-mdc-tab-link {
	transition: opacity 250ms;
}

.mat-button-toggle-label-content .mat-pseudo-checkbox {
	display: none;
}

// use this mixin to the :host selector of any angular component to override mat styles
@mixin inputStyles($backgroundColor: getColor('secondaryBackgroundColor')) {
	// overrides for mat-form-field, which handles labels and formatting
	// for various mat components like mat-select and mat-input
	::ng-deep mat-form-field.mat-form-field-appearance-fill:not(.no-mat-overrides) {
		.mat-mdc-text-field-wrapper {
			background-color: transparent;
			padding-left: 0;
			padding-right: 0;
		}

		// this makes a nice border around our mat-form-fields
		// and labels
		.mat-mdc-form-field-infix {
			// this preserves some top padding that material UI
			// applies with an transparent top border (!?!?!?)
			margin-top: $input-margin-top;

			// these are styles we want
			border: $input-border;
			border-radius: $input-border-radius;
			padding: $form-field-padding-top-bottom $form-field-padding-left-right !important;
			padding-left: $form-field-padding-left-right !important;
			padding-right: $form-field-padding-left-right !important;
			width: 100%;
			//
			min-height: inherit;
			max-height: spacingLevel(8);
			display: flex;
		}

		.mat-mdc-form-field-focus-overlay {
			background: none;
		}

		.mat-mdc-form-field-infix,
		.mat-mdc-form-field-icon-prefix,
		.mat-mdc-form-field-icon-suffix {
			background-color: $backgroundColor;
		}
		&:not(.mat-mdc-paginator-page-size-select) .mat-mdc-form-field-infix {
			// original mat- components are 180px
			width: 144px;
		}

		&.small-field {
			@include fixMatFormFieldWidth(70px);
		}

		&.medium-field {
			@include fixMatFormFieldWidth(100px);
		}

		.mat-mdc-form-field-icon-prefix,
		.mat-mdc-form-field-icon-suffix {
			z-index: 2;
			border: $input-border;
			padding-left: $form-field-padding-left-right !important;
			line-height: inherit;
			top: 0.45em;
			padding-top: 0.43em;
			padding-bottom: 0.44em;

			span {
				display: block;
			}
		}

		.mat-mdc-form-field-icon-suffix {
			border-radius: 0px $input-border-radius $input-border-radius 0px;
			padding-right: $form-field-padding-left-right !important;
			border-left-width: 0;
			margin-left: -5px;
		}

		.mat-mdc-form-field-icon-prefix {
			border-radius: $input-border-radius 0px 0px $input-border-radius;
			padding-right: 0 !important;
			border-right-width: 0;
			margin-right: -5px;
		}

		.mdc-floating-label {
			top: spacingLevel(3, 1.5) !important;
			letter-spacing: normal;
		}

		.mdc-floating-label--float-above {
			top: spacingLevel(1) !important;
			left: 0px !important;
		}

		.mdc-line-ripple {
			display: none;
		}

		.mat-mdc-select {
			display: flex;
			.mat-mdc-select-arrow-wrapper {
				transform: translateY(0);
			}
		}

		.mat-mdc-form-field-hint-wrapper,
		.mat-mdc-form-field-error-wrapper {
			padding: 0;
		}
	}

	// ng-* overrides as well
	::ng-deep ng-select {
		&,
		& input {
			color: getColor('fontColor');
		}

		.ng-dropdown-panel {
			background-color: $backgroundColor;
			color: getColor('fontColor');
		}

		.ng-optgroup,
		.ng-tag-label {
			color: getColor('secondaryFontColor') !important;
		}

		.ng-value-label,
		.ng-value {
			color: getColor('fontColor');
		}

		.ng-select-container {
			position: relative;
			border: $input-border !important;
			border-radius: $input-border-radius;
			padding: ($form-field-padding-top-bottom - 1) $form-field-padding-left-right;
			overflow: visible !important;
			min-height: unset !important;
			margin-top: $input-margin-top + 0.2em;
			background-color: $backgroundColor;
			color: getColor('fontColor');
			@include flex-row(center-left);

			&:after {
				display: none;
			}

			&.ng-has-value {
				.ng-placeholder {
					top: 3px;
				}
			}
		}

		.ng-placeholder {
			color: getColor('secondaryFontColor') !important;
		}

		.ng-value-container {
			padding: 0 0 0 0.2em !important;
			border-top: 0 !important;
			margin-bottom: $ng-select-bottom-margin * -1;

			.ng-input {
				left: $form-field-padding-left-right !important;
				@include flex-row(center-left);
			}

			.ng-value {
				margin: 0 $ng-select-bottom-margin $ng-select-bottom-margin 0;
				padding: 0 !important;
				z-index: 2;
			}
		}

		.ng-arrow-wrapper {
			bottom: 2px !important;
			border-top: 0 !important;
			.ng-arrow {
				color: getColor('secondaryFontColor') !important;
			}
		}

		.ng-clear-wrapper {
			color: getColor('secondaryFontColor') !important;
			bottom: -2px !important;
			border-top: 0 !important;
		}

		&.ng-select-focused {
			.ng-placeholder {
				color: getColor('accentPurple') !important;
				top: 3px;
			}
		}

		.ng-dropdown-panel .ng-dropdown-panel-items {
			.ng-option,
			.ng-optgroup {
				height: 2.25em;
				line-height: 2.25em;
				min-height: 2.25em;
				color: getColor('fontColor') !important;
				transition: background-color 250ms;

				&.ng-option-selected {
					background-color: getColor('focusedBackground');
				}

				&.ng-option-marked {
					background-color: getColor('hoverBackground');
				}
			}
		}

		// mobile-only override that will be sure to draw
		// the ng-select dropdown above labels (which have a translatez property)
		@include mobileOnly() {
			ng-dropdown-panel {
				transform: translateZ(1px);
			}
		}
	}

	::ng-deep {
		mat-button-toggle-group {
			height: 44px;
			.mat-button-toggle-label-content {
				line-height: 44px;
			}
		}
	}

	// custom label
	::ng-deep .custom-view-label {
		@include matLabel();
		text-transform: capitalize;
	}
}

/**
 * calendars are similar to modals in that they're rendered globally.
 * this mixin should be applied _outside_ of `:host` in a component's scss file
 * this needs to exist outside of the :host selector so it applies to global elements not scopped
 * to any given component
 *
 * usage: something-with-calendar.component.scss
 *
 * 	@import 'redesign';
 *
 * 	// apply the calendar styles to any `<mat-calendar>` or `<mat-datepicker>` in use
 * 	@include applyMatCalendarOverrides();
 */
@mixin applyMatCalendarOverrides() {
	// calendar stuff
	::ng-deep {
		.mat-datepicker-content {
			box-shadow: $overlayBoxShadow !important;
			border-radius: 8px !important;
			margin-top: 4px;

			mat-calendar {
				margin: 16px;
				max-height: 310px;
			}
		}

		.mat-calendar-header {
			display: block;
			position: relative;
			padding-top: 0px !important;

			// hide a bunch of mat calendar stuff
			.mat-calendar-spacer,
			.mat-calendar-arrow {
				display: none;
			}

			.mat-calendar-previous-button,
			.mat-calendar-next-button {
				position: absolute;
			}

			.mat-calendar-previous-button {
				left: 0;
			}

			.mat-calendar-next-button {
				right: 0;
			}

			.mat-calendar-period-button {
				margin: 0 auto;
				font-weight: normal;
				text-transform: lowercase;
				font-size: 14px;
				letter-spacing: 0.25px;

				.mdc-button__label::first-letter {
					text-transform: uppercase;
				}
			}
		}

		.mat-calendar-table-header-divider {
			display: none;
		}

		.mat-calendar-body-label {
			opacity: 0;

			&:last-child {
				display: none;
			}
		}

		.mat-calendar-controls {
			margin-top: 0 !important;
		}
	}
}

/**
 * Reduces the height of the mat-toggles it encounters. Accepts the number of
 * elements inside the toggle so that it can calculate the required width.
 *
*/
@mixin applyMatToggleOverrides($numberOfElements) {
	mat-button-toggle-group {
		width: 100%;
		height: 32px;
		::ng-deep {
			border: 1px solid getColor('dropShadowColor');
			box-shadow: none;
		}
	}

	mat-button-toggle {
		flex-basis: calc(100% / #{$numberOfElements});
	}

	::ng-deep .mat-button-toggle-button .mat-button-toggle-label-content {
		line-height: 32px !important;
	}
}

@mixin applyMatSelectOverrides() {
	mat-select {
		position: relative;
		border: $input-border !important;
		border-radius: $input-border-radius;
		padding: ($form-field-padding-top-bottom - 3) $form-field-padding-left-right;
		overflow: visible;
		min-height: unset;
		@include flex-row(center);
	}
}

@mixin applySmallMatIconButtonOverrides() {
	max-width: spacingLevel(8) !important;
	width: spacingLevel(8) !important;
	max-height: spacingLevel(8) !important;

	mat-icon,
	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

::ng-deep {
	.cdk-keyboard-focused {
		outline: 2px solid getColor('prismPurple');
		outline-offset: 2px;
	}
}
