/**
 2020 Prism redesign
 Based on Jesse's designs in Figma

 https://www.figma.com/file/W5PQMWKQxrsbgrgljh4ivor9/Prism-Design-System?node-id=0%3A1
 */
@import './redesign/colors';
@import './redesign/borders-and-shadows';
@import './redesign/mat-overrides';
@import './redesign/mixins';
@import './redesign/vars';
@import './redesign/simple-table';
@import './redesign/turbo-table';
@import './redesign/z-indices';
@import './redesign/animations';
@import './redesign/functions';
@import './redesign/forms';
@import './redesign/global-classes';
