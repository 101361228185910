@import 'theme';
@import 'redesign';
@import 'toastui-editor.css';
@import './redesign/prism-tailwind-like-helpers.scss';

html,
body {
	height: 100%;
}

body {
	transition: background-color 250ms, color 250ms;
	background-color: getColor('secondaryBackgroundColor');
	color: getColor('fontColor');
	font-family: 'Roboto', sans-serif;
	min-width: auto !important;

	&.lock-scroll {
		@include mobileOnly() {
			overflow-y: hidden;
		}
	}
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

h1 {
	font-size: spacingLevel(6);
}

h2 {
	font-size: spacingLevel(5);
}

h3 {
	font-size: spacingLevel(4);
}

h4 {
	font-size: spacingLevel(4) - 1;
}

h5 {
	font-size: spacingLevel(4) - 2;
}

h6 {
	font-size: spacingLevel(4) - 3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: spacingLevel(2);
	font-weight: 500;
}

hr {
	margin: spacingLevel(4) 0;
	border: 0;
	border-top: 1px solid getColor('borderColor');
}

[hidden] {
	display: none !important;
}

.table {
	width: 100%;
	margin-bottom: 1rem;
	vertical-align: top;
}

table {
	caption-side: bottom;
	border-collapse: collapse;
}

th {
	text-align: inherit;
	text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

.mat-mdc-menu-content {
	@include flex-column();

	& > button {
		text-align: left;
	}
}

.mat-mdc-input-element {
	box-sizing: border-box !important;
}

a,
a:hover {
	color: getColor('fontColor');
	// override stupid webkit default style
	text-decoration: none;

	/**
	* We want to always show our blue color for links, but not when they are
	* used as a button or menu items, or tab links, through a angular material
	* directives
	*/
	&:not([mat-button]):not([mat-icon-button]):not([mat-raised-button]):not([mat-stroked-button]):not(
			[mat-flat-button]
		):not([mat-tab-link]):not([mat-menu-item]):not([mat-list-item]):not(.visually-disabled):not(
			.event-tour-link
		):not(.fc-col-header-cell-cushion):not(.fc-daygrid-day-number):not(.fc-daygrid-event):not(.event-card-link):not(
			.prism-help-article-link
		) {
		color: getColor('linkBlue');
	}
}

button,
a {
	&.primary-button,
	&.primary {
		@include primaryButton();
	}

	&.primary-button-outline {
		@include primaryButtonOutline();
	}

	&.secondary-button {
		@include secondaryButton();
	}

	&.green-primary-button {
		@include greenPrimaryButton();
	}

	&.danger-button,
	&.danger {
		@include dangerButton();
	}

	&.small-button,
	&[mat-button].small-button {
		@include smallButton();
	}

	&.neutral-button {
		@include neutralButton();
	}

	&.button-with-icon {
		&,
		&:not([disabled]) {
			@include buttonWithIcon();
		}
	}

	&.danger-button-outline {
		@include dangerButtonOutline();
	}

	&.warning-button {
		@include warningButton();
	}

	&.custom-button {
		@include customButton();
	}
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

textarea {
	resize: vertical;
}

.alert {
	--alert-border: #{getColor('defaultLevelBorder')};
	--alert-background: #{getColor('defaultLevelBackground')};
	--alert-color: #{getColor('defaultLevelColor')};

	padding: spacingLevel(4);
	margin-bottom: spacingLevel(4);
	border-radius: spacingLevel(2);
	// variables are set above, and overridden below
	border: 1px solid var(--alert-border);
	background-color: var(--alert-background);
	color: var(--alert-color);

	&.info {
		--alert-border: #{getColor('infoLevelBorder')};
		--alert-background: #{getColor('infoLevelBackground')};
		--alert-color: #{getColor('infoLevelColor')};
	}

	&.error {
		--alert-border: #{getColor('errorLevelBorder')};
		--alert-background: #{getColor('errorLevelBackground')};
		--alert-color: #{getColor('errorLevelColor')};
	}

	&.warning {
		--alert-border: #{getColor('warningLevelBorder')};
		--alert-background: #{getColor('warningLevelBackground')};
		--alert-color: #{getColor('warningLevelColor')};
	}

	&.success {
		--alert-border: #{getColor('successLevelBorder')};
		--alert-background: #{getColor('successLevelBackground')};
		--alert-color: #{getColor('successLevelColor')};
	}
}

.pull-right {
	float: right;
}

.clearfix::after {
	display: block;
	clear: both;
	content: '';
}

// make it possible to have mat tabs be variable width
.mat-mdc-tab-links {
	white-space: nowrap;
	overflow-x: auto;

	.mat-mdc-tab-link {
		display: inline-flex;
		min-width: 110px;
		flex-basis: auto;
		/* default value */
		flex-grow: 0 !important;
	}
}

.loading,
.loading::before,
.loading::after {
	position: absolute;
	top: 50%;
	left: 50%;
	border: 1px solid getColor('dropShadowColor');
	border-left-color: getColor('dropShadowColor');
	-webkit-border-radius: 999px;
	-moz-border-radius: 999px;
	border-radius: 999px;
}

.loading,
.loading::before,
.loading::after {
	margin: -25px 0 0 -25px;
	height: 50px;
	width: 50px;
	-webkit-animation: animation-rotate 1000ms linear infinite;
	-moz-animation: animation-rotate 1000ms linear infinite;
	-o-animation: animation-rotate 1000ms linear infinite;
	animation: animation-rotate 1000ms linear infinite;
}

.loading::before,
.loading::after {
	content: '';
}

@-webkit-keyframes animation-rotate {
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes animation-rotate {
	100% {
		-moz-transform: rotate(360deg);
	}
}

@-o-keyframes animation-rotate {
	100% {
		-o-transform: rotate(360deg);
	}
}

@keyframes animation-rotate {
	100% {
		transform: rotate(360deg);
	}
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
	z-index: 2;
}

.snack-bar-success {
	.mdc-snackbar__label {
		color: getColor('alwaysWhite') !important;
	}

	.mdc-snackbar__surface::before {
		background: getColor('accentGreen') !important;
	}
}

.snack-bar-error,
.mat-mdc-snack-bar-container.snack-bar-error {
	max-width: 50vw;

	.mdc-snackbar__label {
		color: getColor('alwaysWhite') !important;
	}

	.mdc-snackbar__surface::before {
		background: getColor('indicatorRed') !important;
	}
}

.mat-accordion-no-padding {
	.mat-expansion-panel-body {
		padding: 0;
	}
}

/* CSS override for the p-table turbo table responsiveness */
/* Responsive */
.p-datatable-responsive .p-datatable-tbody > tr > td .ui-column-title {
	display: none;
}

.mat-expansion-panel-content.mat-expanded {
	overflow: auto !important;
}

/* Loader */
.p-datatable-loading {
	position: absolute;
	width: 100%;
	height: 100%;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=10)';
	opacity: 0.1;
	z-index: 1;
}

.p-datatable-loading-content {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 2;
	margin-top: -1em;
	margin-left: -1em;
}

/* This is to override the default max-width for the primeng datatable to
     switch to stacked mode, we want it to change at $stackTurboTableWidth */
@media (max-width: $stackTurboTableWidth) {
	.p-datatable-responsive .p-datatable-thead > tr > th,
	.p-datatable-responsive .p-datatable-tfoot > tr > td {
		display: none !important;
	}

	.p-datatable-responsive .p-datatable-tbody > tr > td {
		text-align: left;
		display: block;
		border: 0 none;
		width: 100% !important;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		float: left;
		clear: left;
	}

	.p-datatable-responsive .p-datatable-tbody > tr > td .ui-column-title {
		padding: 0.4em;
		min-width: 30%;
		display: inline-block;
		margin: -0.4em 1em -0.4em -0.4em;
		font-weight: bold;
	}

	.ui-datatable-reflow thead th,
	.ui-datatable-reflow tfoot td {
		display: none !important;
	}

	.ui-datatable-reflow .ui-datatable-data > tr > td {
		text-align: left;
		display: block;
		border: 0 none;
		width: 100% !important;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		float: left;
		clear: left;
	}

	.ui-datatable-reflow .ui-datatable-data.ui-widget-content {
		border: 0 none;
	}

	.ui-datatable-reflow .ui-datatable-data tr.ui-widget-content {
		border-left: 0 none;
		border-right: 0 none;
	}

	.ui-datatable-reflow .ui-datatable-data td .ui-column-title {
		padding: 0.4em;
		min-width: 30%;
		display: inline-block;
		margin: -0.4em 1em -0.4em -0.4em;
		font-weight: bold;
	}

	.ui-datatable-reflow.ui-datatable-scrollable .ui-datatable-scrollable-body colgroup {
		display: block;
	}
}

.tooltip-underline {
	border-bottom: 2px dotted getColor('selectedGray');
}

// ng-select overrides
.ng-select {
	// set a purple background for the pills/chips that appear in
	// ng-select when selected
	&.ng-select-multiple {
		.ng-select-container .ng-value-container .ng-value {
			@include flex(row-reverse, center);
			background-color: transparent !important;
			margin-right: spacingLevel(4);

			.ng-value-icon {
				color: getColor('alwaysPrimaryDark') !important;
				padding-right: initial;
				margin-left: spacingLevel(4, -1);
				z-index: 1;
			}
		}

		.ng-select-container .ng-value-container .ng-value .ng-value-label {
			@extend %chip-container-shared;
			padding: 0 spacingLevel(5) !important;
			height: 30px;
			line-height: 28px;
			color: getColor('alwaysPrimaryDark') !important;
			background-color: getColor('chipBackgroundColor') !important;

			&,
			.ng-value-label {
				--fontColor: #{getColor('alwaysPrimaryDark')};
			}
		}
	}

	&.ng-select-disabled {
		.ng-value {
			opacity: 0.3;
		}
	}

	// set various purple colors for focused values
	&.ng-select-focused .ng-select-container {
		.ng-arrow-wrapper .ng-arrow,
		.ng-value-container .ng-placeholder {
			color: getColor('accentPurple');
		}

		&:after {
			border-color: getColor('prismPurple');
		}
	}

	.ng-arrow-wrapper,
	.ng-clear-wrapper {
		align-self: center !important;
	}
}

.mat-mdc-checkbox-checked ::ng-deep .mdc-checkbox__background {
	background-color: getColor('prismPurple') !important;
}

// this overwrites a !important in the mat theme
body.dark-theme .mat-mdc-checkbox .mat-mdc-checkbox-checkmark-path {
	stroke: getColor('alwaysWhite') !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked {
	.mdc-radio__inner-circle {
		background-color: getColor('prismPurple') !important;
	}
	.mdc-radio__outer-circle {
		border-color: getColor('prismPurple') !important;
	}
}

.swal2-popup {
	background-color: getColor('secondaryBackgroundColor') !important;
	color: getColor('fontColor') !important;
}

p-editor .ql-editor {
	min-height: var(--quillEditorHeight, 100%);
}

@include mobileOnly() {
	input,
	textarea {
		font-size: 16px;
	}
	// make it so mat dialogs are not longer than the height of a phone screen
	::ng-deep .mat-mdc-dialog-container {
		max-height: 100vh;
	}
}

.swal2-styled.swal2-confirm {
	background-color: getColor('prismPurple') !important;
}

.mat-mdc-tab-nav-bar {
	box-shadow: $bottomBoxShadow;
	border-bottom: 0;
	margin-bottom: 25px;
	background-color: getColor('backgroundColor');
}

.p-dialog-header,
.p-dialog-content,
.p-dialog-footer {
	background-color: getColor('backgroundColor') !important;
	color: getColor('fontColor') !important;
}

::ng-deep {
	body button[mat-button].visually-disabled {
		background-color: getColor('disabledGray');
		color: getColor('backgroundColor');
	}
	body a.visually-disabled {
		color: getColor('disabledGray');
		cursor: pointer;

		&:hover {
			color: getColor('disabledGray');
		}
	}

	// make prism-icon look like mat-icons in mat-menus
	.mat-mdc-menu-item {
		::ng-deep mat-icon prism-icon svg {
			width: 100%;
			height: 100%;
		}
	}
}
