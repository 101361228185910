/**
 * Positioning utils
 */

/**
 * This holds a map between possible align-items shorthand values
 * and their proper css value to apply
 */
$flexAlignMap: (
	normal: normal,
	top: flex-start,
	bottom: flex-end,
	center: center,
	line: baseline,
	stretch: stretch,
);

/**
 * This holds a map between possible justify-content shorthand values
 * and their proper css value to apply
 */
$flexJustifyMap: (
	left: flex-start,
	center: center,
	right: flex-end,
	even: space-evenly,
	between: space-between,
	around: space-around,
);

/**
 * These following maps relate possible flex positioning shorthand values
 * and their proper justify-content/align-items shorthand map value,
 * which are then used to get the underlying css value
 *
 * 1. top-left = align-items: flex-start, justify-content: flex-start
 *      row example
 *      column example
 *
 * 2. top-center = align-items: flex-start, justify-content: center
 *      row example
 *      column example
 *
 * 3. top-right = align-items: flex-start, justify-content: flex-end
 *      row example
 *      column example
 *
 * 4. top-even = align-items: flex-start, justify-content: space-evenly
 *      row example
 *      column example
 *
 * 5. top-between = align-items: flex-start, justify-content: space-between
 *      row example
 *      column example
 *
 * 6. top-around = align-items: flex-start, justify-content: space-around
 *      row example
 *      column example
 *
 * 7. normal-left (DEFAULT) = align-items: normal, justify-content: flex-start
 *      row example
 *      column example
 *
 * 8. normal-center = align-items: normal, justify-content: center
 *      row example
 *      column example
 *
 * 9. normal-right = align-items: normal, justify-content: flex-end
 *      row example
 *      column example
 *
 * 10. normal-even = align-items: normal, justify-content: space-evenly
 *      row example
 *      column example
 *
 * 11. normal-between = align-items: normal, justify-content: space-between
 *      row example
 *      column example
 *
 * 12. normal-around = align-items: normal, justify-content: space-around
 *      row example
 *      column example
 *
 * 13. center-left = align-items: center, justify-content: flex-start
 *      row example
 *      column example
 *
 * 14. center = align-items: center, justify-content: center
 *      row example
 *      column example
 *
 * 15. center-right = align-items: center, justify-content: flex-end
 *      row example
 *      column example
 *
 * 16. center-even = align-items: center, justify-content: space-evenly
 *      row example
 *      column example
 *
 * 17. center-between = align-items: center, justify-content: space-between
 *      row example
 *      column example
 *
 * 18. center-around = align-items: center, justify-content: space-around
 *      row example
 *      column example
 *
 * 19. bottom-left = align-items: flex-end, justify-content: flex-start
 *      row example
 *      column example
 *
 * 20. bottom-center = align-items: flex-end, justify-content: center
 *      row example
 *      column example
 *
 * 21. bottom-right = align-items: flex-end, justify-content: flex-end
 *      row example
 *      column example
 *
 * 22. bottom-even = align-items: flex-end, justify-content: space-evenly
 *      row example
 *      column example
 *
 * 23. bottom-between = align-items: flex-end, justify-content: space-between
 *      row example
 *      column example
 *
 * 24. bottom-around = align-items: flex-end, justify-content: space-around
 *      row example
 *      column example
 *
 * 25. line-left = align-items: baseline, justify-content: flex-start
 *      row example
 *      column example
 *
 * 26. line-center = align-items: baseline, justify-content: center
 *      row example
 *      column example
 *
 * 27. line-right = align-items: baseline, justify-content: flex-end
 *      row example
 *      column example
 *
 * 28. line-even = align-items: baseline, justify-content: space-evenly
 *      row example
 *      column example
 *
 * 29. line-between = align-items: baseline, justify-content: space-between
 *      row example
 *      column example
 *
 * 30. line-around = align-items: baseline, justify-content: space-around
 *      row example
 *      column example
 *
 * 31. stretch-left = align-items: stretch, justify-content: flex-start
 *      row example
 *      column example
 *
 * 32. stretch-center = align-items: stretch, justify-content: center
 *      row example
 *      column example
 *
 * 33. stretch-right = align-items: stretch, justify-content: flex-end
 *      row example
 *      column example
 *
 * 34. stretch-even = align-items: stretch, justify-content: space-evenly
 *      row example
 *      column example
 *
 * 35. stretch-between = align-items: stretch, justify-content: space-between
 *      row example
 *      column example
 *
 * 36. stretch-around = align-items: stretch, justify-content: space-around
 *      row example
 *      column example
 *
 */
$flexPositionJustifyMap: (
	top-left: left,
	top-center: center,
	top-right: right,
	top-even: even,
	top-between: between,
	top-around: around,
	normal-left: left,
	normal-center: center,
	normal-right: right,
	normal-even: even,
	normal-between: between,
	normal-around: around,
	center-left: left,
	center: center,
	center-right: right,
	center-even: even,
	center-between: between,
	center-around: around,
	bottom-left: left,
	bottom-center: center,
	bottom-right: right,
	bottom-even: even,
	bottom-between: between,
	bottom-around: around,
	line-left: left,
	line-center: center,
	line-right: right,
	line-even: even,
	line-between: between,
	line-around: around,
	stretch-left: left,
	stretch-center: center,
	stretch-right: right,
	stretch-even: even,
	stretch-between: between,
	stretch-around: around,
	initial: initial,
);

$flexPositionAlignMap: (
	top-left: top,
	top-center: top,
	top-right: top,
	top-even: top,
	top-between: top,
	top-around: top,
	normal-left: normal,
	normal-center: normal,
	normal-right: normal,
	normal-even: normal,
	normal-between: normal,
	normal-around: normal,
	center-left: center,
	center: center,
	center-right: center,
	center-even: center,
	center-between: center,
	center-around: center,
	bottom-left: bottom,
	bottom-center: bottom,
	bottom-right: bottom,
	bottom-even: bottom,
	bottom-between: bottom,
	bottom-around: bottom,
	line-left: line,
	line-center: line,
	line-right: line,
	line-even: line,
	line-between: line,
	line-around: line,
	stretch-left: stretch,
	stretch-center: stretch,
	stretch-right: stretch,
	stretch-even: stretch,
	stretch-between: stretch,
	stretch-around: stretch,
	initial: initial,
);

@mixin flex($direction: row, $align: normal, $justify: left, $wrap: nowrap, $gap: 0) {
	display: flex;
	flex-direction: #{$direction};
	justify-content: map-get($flexJustifyMap, #{$justify});
	align-items: map-get($flexAlignMap, #{$align});
	flex-wrap: #{$wrap};
	gap: #{$gap};
}

@mixin flex-row($position: normal-left, $wrap: nowrap, $gap: 0) {
	@include flex(
		row,
		map-get($flexPositionAlignMap, #{$position}),
		map-get($flexPositionJustifyMap, #{$position}),
		#{$wrap},
		#{$gap}
	);
}

@mixin flex-column($position: normal-left, $wrap: nowrap, $gap: 0) {
	@include flex(
		column,
		map-get($flexPositionAlignMap, #{$position}),
		map-get($flexPositionJustifyMap, #{$position}),
		#{$wrap},
		#{$gap}
	);
}
