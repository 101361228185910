@use '@angular/material' as mat;
@use 'sass:map';
@import 'palette';
@import './redesign/colors';
@include mat.core();

// by default, we do a light theme
body {
	@include setThemeVariables('light');

	.mat-mdc-button-persistent-ripple {
		background: getColor('alwaysBlack');
	}

	@include mat.all-component-themes($light-theme);
}

body.dark-theme {
	@include setThemeVariables('dark');

	.mat-mdc-button-persistent-ripple {
		background: getColor('alwaysWhite');
	}

	// this gets the time input to change font colors in dark mode
	// https://stackoverflow.com/a/64184578/1337683
	input[type='time']::-webkit-calendar-picker-indicator {
		filter: invert(100%);
	}

	/**
	 * this is a dark-mode-only fix for the mat-button-toggle component. by default,
	 * the wrong option appeared clickable. this gets it so un-selected options are the
	 * same color as buttons, so that they appear clickable
	 *
	 * this matches the UX for light-mode, and is only an issue in dark mode
	 */
	mat-button-toggle {
		&:not(.mat-button-toggle-checked) {
			background-color: getColor('backgroundColor');
		}

		&.mat-button-toggle-checked {
			background-color: getColor('secondaryBorderColor');
		}
	}

	@include mat.all-component-colors($dark-theme);
}

// Typographies
$base-font-size: 14px;
$global-typography: mat.define-typography-config(
	$font-family: 'Roboto, "Helvetica Neue", sans-serif',
	$body-2:
		mat.define-typography-level(
			$font-size: spacingLevel(4),
		),
);

$table-typography: mat.define-typography-config(
	$subtitle-2:
		mat.define-typography-level(
			$font-size: spacingLevel(3),
			$font-weight: 500,
		),
	$body-2:
		mat.define-typography-level(
			$font-size: $base-font-size,
			$line-height: spacingLevel(5),
		),
);

$tabs-typography: mat.define-typography-config(
	$button:
		mat.define-typography-level(
			$font-size: $base-font-size,
			$font-weight: 500,
			$letter-spacing: normal,
		),
);

$button-typography: mat.define-typography-config(
	$button:
		mat.define-typography-level(
			$font-size: $base-font-size,
			$letter-spacing: 0.75px,
			$font-weight: 500,
			$line-height: 36px,
		),
);

$menu-typography: mat.define-typography-config(
	$body-1:
		mat.define-typography-level(
			$font-size: $base-font-size,
			$letter-spacing: normal,
		),
);

$form-field-typography: mat.define-typography-config(
	$body-1:
		mat.define-typography-level(
			$font-size: $base-font-size,
		),
);

@include mat.all-component-typographies($global-typography);
@include mat.table-typography($table-typography);
@include mat.tabs-typography($tabs-typography);
@include mat.button-typography($button-typography);
@include mat.menu-typography($menu-typography);
@include mat.checkbox-typography($form-field-typography);
@include mat.radio-typography($form-field-typography);
