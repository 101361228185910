/**
 * contained in this file are global styles that we want to apply to all our form fields, but do not
 * require each component to import a sass file to implement
 *
 * as an example, the "clear" button is supported in multiple input components, and should be styled the same in simple
 * forms and when the components are consumed in a bespoke manner. those styles are put here, instead of at the component level
 */

.simple-form-field-base {
	// best way to verically center an absolute div
	// https://www.smashingmagazine.com/2013/08/absolute-horizontal-vertical-centering-css/#transforms
	.clear-value-button {
		position: absolute;
		right: 0;
		top: 0;
		margin: auto;
		top: 50%;
		right: 0;
		transition: opacity 250ms;
		-webkit-transform: translate(-0.3em, -50%);
		-ms-transform: translate(-0.3em, -50%);
		transform: translate(-0.3em, -50%);

		&[disabled] {
			pointer-events: none;
			opacity: 0;
		}
	}
}
