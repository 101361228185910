// PRSM Design color system palettes
/**
 * These variables hold a particular color palette with all its variations and contrasts
 * Ideally these should be the only colors used within the application.
 */

// primary colors
$prism-primary: (
	25: #fcfaff,
	50: #f9f5ff,
	100: #f4ebff,
	200: #e9d7fe,
	300: #d6bbfb,
	400: #b692f6,
	500: #9e77ed,
	600: #7f56d9,
	700: #6941c6,
	800: #53389e,
	900: #42307d,
	950: #2c1c5f,
	contrast: (
		25: #7f56d9,
		50: #7f56d9,
		100: #7f56d9,
		200: #7f56d9,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-gray: (
	25: #fcfcfd,
	50: #f9fafb,
	100: #f2f4f7,
	200: #eaecf0,
	300: #d0d5dd,
	400: #98a2b3,
	500: #667085,
	600: #475467,
	700: #344054,
	800: #1d2939,
	900: #101828,
	950: #0c111d,
	contrast: (
		25: #667085,
		50: #667085,
		100: #667085,
		200: #667085,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-error: (
	25: #fffbfa,
	50: #fef3f2,
	100: #fee4e2,
	200: #fecdca,
	300: #fda29b,
	400: #f97066,
	500: #f04438,
	600: #d92d20,
	700: #b42318,
	800: #912018,
	900: #7a271a,
	950: #55160c,
	contrast: (
		25: #b42318,
		50: #b42318,
		100: #b42318,
		200: #b42318,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-warning: (
	25: #fffcf5,
	50: #fffaeb,
	100: #fef0c7,
	200: #fedf89,
	300: #fec84b,
	400: #fdb022,
	500: #f79009,
	600: #dc6803,
	700: #b54708,
	800: #93370d,
	900: #7a2e0e,
	950: #4e1d09,
	contrast: (
		25: #b54708,
		50: #b54708,
		100: #b54708,
		200: #b54708,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-success: (
	25: #f6fef9,
	50: #ecfdf3,
	100: #dcfae6,
	200: #abefc6,
	300: #75e0a7,
	400: #47cd89,
	500: #17b26a,
	600: #079455,
	700: #067647,
	800: #085d3a,
	900: #074d31,
	950: #053321,
	contrast: (
		25: #067647,
		50: #067647,
		100: #067647,
		200: #067647,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

// secondary colors
$prism-gray-blue: (
	25: #fcfcfd,
	50: #f8f9fc,
	100: #eaecf5,
	200: #d5d9eb,
	300: #b3b8db,
	400: #717bbc,
	500: #4e5ba6,
	600: #3e4784,
	700: #363f72,
	800: #293056,
	900: #101323,
	950: #0d0f1c,
	contrast: (
		25: #4e5ba6,
		50: #4e5ba6,
		100: #4e5ba6,
		200: #4e5ba6,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-gray-cool: (
	25: #fcfcfd,
	50: #f9f9fb,
	100: #eff1f5,
	200: #dcdfea,
	300: #b9c0d4,
	400: #7d89b0,
	500: #5d6b98,
	600: #4a5578,
	700: #404968,
	800: #30374f,
	900: #111322,
	950: #0e101b,
	contrast: (
		25: #5d6b98,
		50: #5d6b98,
		100: #5d6b98,
		200: #5d6b98,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-gray-modern: (
	25: #fcfcfd,
	50: #f8fafc,
	100: #eef2f6,
	200: #e3e8ef,
	300: #cdd5df,
	400: #9aa4b2,
	500: #697586,
	600: #4b5565,
	700: #364152,
	800: #202939,
	900: #121926,
	950: #0d121c,
	contrast: (
		25: #697586,
		50: #697586,
		100: #697586,
		200: #697586,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-gray-neutral: (
	25: #fcfcfd,
	50: #f9fafb,
	100: #f3f4f6,
	200: #e5e7eb,
	300: #d2d6db,
	400: #9da4ae,
	500: #6c737f,
	600: #4d5761,
	700: #384250,
	800: #1f2a37,
	900: #111927,
	950: #0d121c,
	contrast: (
		25: #6c737f,
		50: #6c737f,
		100: #6c737f,
		200: #6c737f,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-gray-iron: (
	25: #fcfcfc,
	50: #fafafa,
	100: #f4f4f5,
	200: #e4e4e7,
	300: #d1d1d6,
	400: #a0a0ab,
	500: #70707b,
	600: #51525c,
	700: #3f3f46,
	800: #26272b,
	900: #18181b,
	950: #131316,
	contrast: (
		25: #70707b,
		50: #70707b,
		100: #70707b,
		200: #70707b,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-gray-true: (
	25: #fcfcfc,
	50: #fafafa,
	100: #f5f5f5,
	200: #e5e5e5,
	300: #d6d6d6,
	400: #a3a3a3,
	500: #737373,
	600: #525252,
	700: #424242,
	800: #292929,
	900: #141414,
	950: #0f0f0f,
	contrast: (
		25: #737373,
		50: #737373,
		100: #737373,
		200: #737373,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-gray-warm: (
	25: #fdfdfc,
	50: #fafaf9,
	100: #f5f5f4,
	200: #e7e5e4,
	300: #d7d3d0,
	400: #a9a29d,
	500: #79716b,
	600: #57534e,
	700: #44403c,
	800: #292524,
	900: #1c1917,
	950: #171412,
	contrast: (
		25: #79716b,
		50: #79716b,
		100: #79716b,
		200: #79716b,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-moss: (
	25: #fafdf7,
	50: #f5fbee,
	100: #e6f4d7,
	200: #ceeab0,
	300: #acdc79,
	400: #86cb3c,
	500: #669f2a,
	600: #4f7a21,
	700: #3f621a,
	800: #335015,
	900: #2b4212,
	950: #1a280b,
	contrast: (
		25: #3f621a,
		50: #3f621a,
		100: #3f621a,
		200: #3f621a,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-green-light: (
	25: #fafef5,
	50: #f3fee7,
	100: #e3fbcc,
	200: #d0f8ab,
	300: #a6ef67,
	400: #85e13a,
	500: #66c61c,
	600: #4ca30d,
	700: #3b7c0f,
	800: #326212,
	900: #2b5314,
	950: #15290a,
	contrast: (
		25: #3f621a,
		50: #3f621a,
		100: #3f621a,
		200: #3f621a,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-green: (
	25: #f6fef9,
	50: #edfcf2,
	100: #d3f8df,
	200: #aaf0c4,
	300: #73e2a3,
	400: #3ccb7f,
	500: #16b364,
	600: #099250,
	700: #087443,
	800: #095c37,
	900: #084c2e,
	950: #052e1c,
	contrast: (
		25: #087443,
		50: #087443,
		100: #087443,
		200: #087443,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-teal: (
	25: #f6fefc,
	50: #f0fdf9,
	100: #ccfbef,
	200: #99f6e0,
	300: #5fe9d0,
	400: #2ed3b7,
	500: #15b79e,
	600: #0e9384,
	700: #107569,
	800: #125d56,
	900: #134e48,
	950: #0a2926,
	contrast: (
		25: #107569,
		50: #107569,
		100: #107569,
		200: #107569,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-cyan: (
	25: #f5feff,
	50: #ecfdff,
	100: #cff9fe,
	200: #a5f0fc,
	300: #67e3f9,
	400: #22ccee,
	500: #06aed4,
	600: #088ab2,
	700: #0e7090,
	800: #155b75,
	900: #164c63,
	950: #0d2d3a,
	contrast: (
		25: #0e7090,
		50: #0e7090,
		100: #0e7090,
		200: #0e7090,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-blue-light: (
	25: #f5fbff,
	50: #f0f9ff,
	100: #e0f2fe,
	200: #b9e6fe,
	300: #7cd4fd,
	400: #36bffa,
	500: #0ba5ec,
	600: #0086c9,
	700: #026aa2,
	800: #065986,
	900: #0b4a6f,
	950: #062c41,
	contrast: (
		25: #026aa2,
		50: #026aa2,
		100: #026aa2,
		200: #026aa2,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-blue: (
	25: #f5faff,
	50: #eff8ff,
	100: #d1e9ff,
	200: #b2ddff,
	300: #84caff,
	400: #53b1fd,
	500: #2e90fa,
	600: #1570ef,
	700: #175cd3,
	800: #1849a9,
	900: #194185,
	950: #102a56,
	contrast: (
		25: #175cd3,
		50: #175cd3,
		100: #175cd3,
		200: #175cd3,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-blue-dark: (
	25: #f5f8ff,
	50: #eff4ff,
	100: #d1e0ff,
	200: #b2ccff,
	300: #84adff,
	400: #528bff,
	500: #2970ff,
	600: #155eef,
	700: #004eeb,
	800: #0040c1,
	900: #00359e,
	950: #002266,
	contrast: (
		25: #004eeb,
		50: #004eeb,
		100: #004eeb,
		200: #004eeb,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-indigo: (
	25: #f5f8ff,
	50: #eef4ff,
	100: #e0eaff,
	200: #c7d7fe,
	300: #a4bcfd,
	400: #8098f9,
	500: #6172f3,
	600: #444ce7,
	700: #3538cd,
	800: #2d31a6,
	900: #2d3282,
	950: #1f235b,
	contrast: (
		25: #3538cd,
		50: #3538cd,
		100: #3538cd,
		200: #3538cd,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-violet: (
	25: #fbfaff,
	50: #f5f3ff,
	100: #ece9fe,
	200: #ddd6fe,
	300: #c3b5fd,
	400: #a48afb,
	500: #875bf7,
	600: #7839ee,
	700: #6927da,
	800: #5720b7,
	900: #491c96,
	950: #2e125e,
	contrast: (
		25: #6927da,
		50: #6927da,
		100: #6927da,
		200: #6927da,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-purple: (
	25: #fafaff,
	50: #f4f3ff,
	100: #ebe9fe,
	200: #d9d6fe,
	300: #bdb4fe,
	400: #9b8afb,
	500: #7a5af8,
	600: #6938ef,
	700: #5925dc,
	800: #4a1fb8,
	900: #3e1c96,
	950: #27115f,
	contrast: (
		25: #5925dc,
		50: #5925dc,
		100: #5925dc,
		200: #5925dc,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-fuchsia: (
	25: #fefaff,
	50: #fdf4ff,
	100: #fbe8ff,
	200: #f6d0fe,
	300: #eeaafd,
	400: #e478fa,
	500: #d444f1,
	600: #ba24d5,
	700: #9f1ab1,
	800: #821890,
	900: #6f1877,
	950: #47104c,
	contrast: (
		25: #9f1ab1,
		50: #9f1ab1,
		100: #9f1ab1,
		200: #9f1ab1,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-pink: (
	25: #fef6fb,
	50: #fdf2fa,
	100: #fce7f6,
	200: #fcceee,
	300: #faa7e0,
	400: #f670c7,
	500: #ee46bc,
	600: #dd2590,
	700: #c11574,
	800: #9e165f,
	900: #851651,
	950: #4e0d30,
	contrast: (
		25: #c11574,
		50: #c11574,
		100: #c11574,
		200: #c11574,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-rose: (
	25: #fff5f6,
	50: #fff1f3,
	100: #ffe4e8,
	200: #fecdd6,
	300: #fea3b4,
	400: #fd6f8e,
	500: #f63d68,
	600: #e31b54,
	700: #c01048,
	800: #a11043,
	900: #89123e,
	950: #510b24,
	contrast: (
		25: #c11574,
		50: #c11574,
		100: #c11574,
		200: #c11574,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-orange-dark: (
	25: #fff9f5,
	50: #fff4ed,
	100: #ffe6d5,
	200: #ffd6ae,
	300: #ff9c66,
	400: #ff692e,
	500: #ff4405,
	600: #e62e05,
	700: #bc1b06,
	800: #97180c,
	900: #771a0d,
	950: #57130a,
	contrast: (
		25: #b93815,
		50: #b93815,
		100: #b93815,
		200: #b93815,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-orange: (
	25: #fefaf5,
	50: #fef6ee,
	100: #fdead7,
	200: #f9dbaf,
	300: #f7b27a,
	400: #f38744,
	500: #ef6820,
	600: #e04f16,
	700: #b93815,
	800: #932f19,
	900: #772917,
	950: #511c10,
	contrast: (
		25: #b93815,
		50: #b93815,
		100: #b93815,
		200: #b93815,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);

$prism-yellow: (
	25: #fefdf0,
	50: #fefbe8,
	100: #fef7c3,
	200: #feee95,
	300: #fde272,
	400: #fac515,
	500: #eaaa08,
	600: #ca8504,
	700: #a15c07,
	800: #854a0e,
	900: #713b12,
	950: #542c0d,
	contrast: (
		25: #a15c07,
		50: #a15c07,
		100: #a15c07,
		200: #a15c07,
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		950: white,
	),
);
