/* ------------------------------------------------------------------------- */
/* Z-INDICES --------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
// A map and mixin to manage all of the Z indexes in the global context

// Z INDEX ORDER LIST ------------------------------------------------------ //
// ------------------------------------------------------------------------- //
// The order of z-indexed elements.
// Items in this list will receive z-indices in ascending order. e.g.
// z("filterOverlayZIndex") < z("desktopHeaderZIndex") < z("mobileFilterZIndex")

$allZIndexItems: (
	'desktopFilterOverlayZIndex',
	'desktopFilterZIndex',
	'desktopHeaderZIndex',
	'mobileHeaderZIndex',
	'mobileMenuZIndex',
	'desktopMenuZIndex',
	'mobileFilterOverlayZIndex',
	'mobileFilterZIndex',
	'holdDetailWrapZIndex',
	'modalZIndex' // STOP... modalZIndex should VERY likely be the last item in this list
);

// MAP --------------------------------------------------------------------- //
// ------------------------------------------------------------------------- //
// programatically build out the allZIndexMap by iterating over the
// allZIndexItems list and assigning a z-index value to them.
// the first item will have a z-index of 100, the second a z-index of 200, etc.

$allZIndexMap: ();
$counter: 1;

@each $item in $allZIndexItems {
	@if map-has-key($allZIndexMap, $item) {
		@warn "z mixin: `#{$item}` exists more than once in $allZIndexItems";
	}

	$allZIndexMap: map-merge(
		$allZIndexMap,
		(
			$item: $counter * 100,
		)
	);
	$counter: $counter + 1;
}

// MIXIN ------------------------------------------------------------------- //
// ------------------------------------------------------------------------- //
// This is the mixin that consumers will call. It will throw
// an error if the z-index is requested for something that is not specified in
// the above allZIndexItems map.

@mixin z($key) {
	z-index: map-get($allZIndexMap, $key);
	@if not map-has-key($allZIndexMap, $key) {
		@warn "z mixin: `#{$key}` does not exist in $allZIndexItems";
	}
}
