@import './mat-overrides';
@import './mixins';

$min-header-height: 52px;
$min-cell-height: 44px;

// purposely global because this often shows up in a modal
::ng-deep .simple-table--tall-textarea textarea {
	min-height: 200px;
	max-height: 90vh;
}

/**
 * $forCell - Optional key name for a field being rendered. If specified, the
 * cell styles will be applied only to cells within the specified column. If omitted,
 * the styles sil be applied to every cell of the table.
*/
@mixin overrideSimpleTableValueStyles($forCell: false) {
	::ng-deep {
		.simple-table-container {
			@if ($forCell) {
				.simple-table-value.for-field--#{$forCell} {
					@content;
				}
			} @else {
				.simple-table-value {
					@content;
				}
			}
		}
	}
}

@mixin overrideSimpleTableCellStyles($forCell: false) {
	::ng-deep {
		.simple-table-container {
			@if ($forCell) {
				.mat-mdc-cell.for-field--#{$forCell},
				.mat-mdc-header-cell.cdk-header-cell.for-field--#{$forCell},
				.mat-mdc-footer-cell.cdk-footer-cell.for-field--#{$forCell} {
					@content;
				}
			} @else {
				.mat-mdc-cell {
					@content;
				}
			}
		}
	}
}

@mixin overrideSimpleTableHeaderStyles($forCell: false) {
	::ng-deep {
		.simple-table-container {
			@if ($forCell) {
				.mat-mdc-header-cell.for-field--#{$forCell} {
					@content;
				}
			} @else {
				.mat-mdc-header-cell {
					@content;
				}
			}
		}
	}
}

@mixin overrideSimpleTableCellStylesByType($type) {
	.simple-table-container {
		.mat-mdc-cell.field-type--#{$type},
		.mat-mdc-header-cell.field-type--#{$type},
		.mat-mdc-footer-cell.cdk-footer-cell.field-type--#{$type} {
			@content;
		}
	}
}

/**
 * this represents styling migrated from events-table, and used in simple-tabl
 */

@mixin simpleTable() {
	width: calc(100% - 10px);

	@include mobileOnly() {
		width: 100%;
	}

	margin: 0 auto;

	.filter-container {
		@include flex-column(center-left, wrap, spacingLevel(4));
		font-size: 1.1em;
		margin-bottom: spacingLevel(3);

		&.no-filter {
			margin-bottom: 0;
		}

		&.hide {
			display: none;

			& + .simple-table-container {
				margin-top: spacingLevel(4);
			}
		}

		.filter-row {
			@include flex-row(center-right, nowrap, spacingLevel(4));
			flex: 1;
			width: 100%;

			.count-and-optional-filter {
				flex: 1;
				@include flex-row(center-left, wrap, spacingLevel(4));

				.table-filter {
					width: 100%;
				}

				@include mobileOnly() {
					@include flex-column(normal-left, wrap, 0);

					::ng-deep .mat-mdc-form-field-subscript-wrapper {
						display: none !important;
					}
				}
			}

			.additional-actions {
				::ng-deep {
					.mat-mdc-text-field-wrapper {
						padding-top: spacingLevel(1);

						.mat-mdc-form-field-infix {
							padding-top: spacingLevel(1, 1.5) !important;
							padding-bottom: spacingLevel(1, 1.5) !important;
						}
					}

					@include desktopOnly() {
						:not(.mdc-text-field--invalid) + .mat-mdc-form-field-subscript-wrapper {
							display: none;
						}
					}
				}
			}

			&.bottom {
				@include flex-row(top-left);
				margin-bottom: 1.3em;

				@include mobileOnly() {
					.count-and-optional-filter {
						max-width: unset !important;
					}
				}

				@include tinyScreens() {
					.count-and-optional-filter {
						max-width: unset !important;
						width: 100%;
					}

					flex-direction: column-reverse;
					align-items: flex-start;
				}
			}
		}

		.mat-icon {
			position: relative;
			font-size: 20px;
			line-height: 24px;
			height: 24px;
			width: 24px;
		}

		.action-button,
		::ng-deep .action-button {
			&:not(.primary) {
				@include smallButton();
			}
		}

		.column-picker {
			font-weight: normal;
		}

		a.action-button {
			text-decoration: none;

			&[disabled] {
				pointer-events: none;
				cursor: default;
			}
		}

		.actions {
			margin-left: auto;
			@include flex-row(center-left);
			flex: 1;

			button .mat-icon {
				top: 0;
			}
		}

		.search-field {
			@include flex-row(center-left);
			padding-bottom: spacingLevel(3);
		}

		.table-filter {
			min-width: 220px;
			display: block;

			::ng-deep {
				.mat-mdc-form-field-subscript-wrapper {
					display: none;
				}
			}
		}

		.count {
			font-size: 14px;
			white-space: nowrap;

			.hidden-count {
				font-size: 11px;
				color: getColor('secondaryFontColor');
				position: absolute;
				right: 0;
				bottom: -5px;
			}
		}
	}

	.mat-mdc-header-row,
	.mat-mdc-footer-row,
	.mat-mdc-row {
		padding: 0;
		min-height: 40px;
	}

	.mat-mdc-row {
		border-bottom: 1px solid getColor('secondaryBorderColor');
	}

	.mat-mdc-footer-row {
		background-color: getColor('secondaryBackgroundColor');
	}

	.mat-column-toggle {
		flex: 0 1 40px;
	}

	// simpleTable mixin is applied inside the :host context
	@include desktopOnly() {
		::ng-deep .mat-mdc-cell {
			input,
			.mat-mdc-select {
				font-size: 13px !important;
			}
		}
	}

	.mat-mdc-cell,
	.mat-mdc-header-cell,
	.mat-mdc-footer-cell,
	.simple-table-value {
		font-size: 13px;

		&.field-type--number {
			text-align: right;
			font-family: system-ui, monospace;
			padding-right: spacingLevel(4);

			.simple-table-value {
				width: 100%;
			}
		}

		&.field-type--boolean {
			.simple-table-value {
				height: 40px;
				@include flex-row(center);
			}

			::ng-deep {
				.mat-sort-header-content {
					justify-content: center;
				}
			}
		}

		&.field-type--editable-number-inline {
			.editable-field-value {
				padding-right: spacingLevel(2);
			}
		}

		&.right-align-header {
			::ng-deep {
				.mat-sort-header-content {
					justify-content: flex-end;
				}
			}

			&.mat-sort-header:not(.mat-sort-header-disabled) ::ng-deep .mat-sort-header-content {
				margin-right: spacingLevel(4) + (spacingLevel(1) / 2);
			}
		}
	}

	.mat-mdc-header-cell {
		color: getColor('secondaryFontColor');
		padding-bottom: 0;
	}

	.simple-table-value {
		@include truncate();
		height: 100%;
	}

	::ng-deep {
		.mat-mdc-header-cell {
			overflow: initial;
		}

		.mat-mdc-header-cell.right-align {
			.mat-sort-header-container,
			.mat-sort-header-content,
			.secondary-header {
				text-align: right;

				span,
				a,
				div {
					text-align: right;
				}
			}
		}

		.mat-mdc-dialog-container {
			position: relative;
		}

		.mat-mdc-cell,
		.mat-mdc-header-cell,
		.mat-mdc-footer-cell {
			&.mat-mdc-table-sticky {
				background-color: inherit;
				align-self: stretch;
				z-index: 20 !important;
			}
		}

		tbody,
		tfoot {
			border-top: none !important;
		}

		@include tinyScreens() {
			.mat-mdc-paginator-range-actions {
				width: 100%;
			}

			.mat-mdc-paginator-range-label {
				margin: 0px spacingLevel(6) 0px 0px;
			}
		}
	}
}
