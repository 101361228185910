/* Flex Container */
.flex {
	display: flex;
}
.inline-flex {
	display: inline-flex;
}
.flex-row {
	flex-direction: row;
}
.flex-row-reverse {
	flex-direction: row-reverse;
}
.flex-col {
	flex-direction: column;
}
.flex-col-reverse {
	flex-direction: column-reverse;
}
.flex-wrap {
	flex-wrap: wrap;
}
.flex-wrap-reverse {
	flex-wrap: wrap-reverse;
}
.flex-no-wrap {
	flex-wrap: nowrap;
}

/* Justify Content */
.justify-start {
	justify-content: flex-start;
}
.justify-center {
	justify-content: center;
}
.justify-end {
	justify-content: flex-end;
}
.justify-between {
	justify-content: space-between;
}
.justify-around {
	justify-content: space-around;
}
.justify-evenly {
	justify-content: space-evenly;
}
/* Align Items */
.items-start {
	align-items: flex-start;
}
.items-center {
	align-items: center;
}
.items-end {
	align-items: flex-end;
}
.items-baseline {
	align-items: baseline;
}
.items-stretch {
	align-items: stretch;
}

/* Align Content */
.content-start {
	align-content: flex-start;
}
.content-center {
	align-content: center;
}
.content-end {
	align-content: flex-end;
}
.content-between {
	align-content: space-between;
}
.content-around {
	align-content: space-around;
}
.content-evenly {
	align-content: space-evenly;
}

/* Align Self */
.self-auto {
	align-self: auto;
}
.self-start {
	align-self: flex-start;
}
.self-center {
	align-self: center;
}
.self-end {
	align-self: flex-end;
}
.self-baseline {
	align-self: baseline;
}
.self-stretch {
	align-self: stretch;
}

/* Flex Grow/Shrink */
.flex-grow {
	flex-grow: 1;
}
.flex-grow-0 {
	flex-grow: 0;
}
.flex-shrink {
	flex-shrink: 1;
}
.flex-shrink-0 {
	flex-shrink: 0;
}

/* Flex Basis */
.basis-auto {
	flex-basis: auto;
}
/* Example basis values (you can add more as needed) */
.basis-1 {
	flex-basis: 1px;
}

/* Order */
.order-first {
	order: -1;
}
.order-last {
	order: 999;
}
.order-none {
	order: 0;
}
/* Example order values (you can add more as needed) */
.order-1 {
	order: 1;
}
.order-2 {
	order: 2;
}

/* Gap */
.gap-0 {
	gap: 0;
}
.gap-1 {
	gap: spacingLevel(1);
}
.gap-2 {
	gap: spacingLevel(2);
}
.gap-3 {
	gap: spacingLevel(3);
}
.gap-4 {
	gap: spacingLevel(4);
}
.gap-5 {
	gap: spacingLevel(5);
}
.gap-6 {
	gap: spacingLevel(6);
}
.gap-7 {
	gap: spacingLevel(7);
}
.gap-8 {
	gap: spacingLevel(8);
}
.gap-9 {
	gap: spacingLevel(9);
}
.gap-10 {
	gap: spacingLevel(10);
}
.h-full {
	height: 100%;
}

/* Text Sizes */
.text-xs {
	font-size: 0.75rem; /* 12px */
}
.text-sm {
	font-size: 0.875rem; /* 14px */
}
.text-base {
	font-size: 1rem; /* 16px */
}
.text-lg {
	font-size: 1.125rem; /* 18px */
}
.text-xl {
	font-size: 1.25rem; /* 20px */
}
.text-2xl {
	font-size: 1.5rem; /* 24px */
}
.text-3xl {
	font-size: 1.875rem; /* 30px */
}
.text-4xl {
	font-size: 2.25rem; /* 36px */
}
.text-5xl {
	font-size: 3rem; /* 48px */
}
.text-6xl {
	font-size: 3.75rem; /* 60px */
}
.text-7xl {
	font-size: 4.5rem; /* 72px */
}
.text-8xl {
	font-size: 6rem; /* 96px */
}
.text-9xl {
	font-size: 8rem; /* 128px */
}

.sp-1 {
	padding-left: spacingLevel(1);
	padding-right: spacingLevel(1);
}
.sp-2 {
	padding-left: spacingLevel(2);
	padding-right: spacingLevel(2);
}
.sp-3 {
	padding-left: spacingLevel(3);
	padding-right: spacingLevel(3);
}
.sp-4 {
	padding-left: spacingLevel(4);
	padding-right: spacingLevel(4);
}
.sp-5 {
	padding-left: spacingLevel(5);
	padding-right: spacingLevel(5);
}
.sp-6 {
	padding-left: spacingLevel(6);
	padding-right: spacingLevel(6);
}
.sp-7 {
	padding-left: spacingLevel(7);
	padding-right: spacingLevel(7);
}
.sp-8 {
	padding-left: spacingLevel(8);
	padding-right: spacingLevel(8);
}
.sp-9 {
	padding-left: spacingLevel(9);
	padding-right: spacingLevel(9);
}
.sp-10 {
	padding-left: spacingLevel(10);
	padding-right: spacingLevel(10);
}

.mb-1 {
	margin-bottom: spacingLevel(1);
}
.mb-2 {
	margin-bottom: spacingLevel(2);
}
.mb-3 {
	margin-bottom: spacingLevel(3);
}
.mb-4 {
	margin-bottom: spacingLevel(4);
}
.mb-5 {
	margin-bottom: spacingLevel(5);
}
.mb-6 {
	margin-bottom: spacingLevel(6);
}
.mb-7 {
	margin-bottom: spacingLevel(7);
}
.mb-8 {
	margin-bottom: spacingLevel(8);
}
.mb-9 {
	margin-bottom: spacingLevel(9);
}
.mb-10 {
	margin-bottom: spacingLevel(10);
}
