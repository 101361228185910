@import './colors';

@mixin noTableBorders() {
	tbody {
		// override primeng styles for no borders
		border: 1px solid getColor('borderColor') !important;
	}

	tr,
	td {
		// override primeng styles for no borders
		border: 0 !important;
	}
}

p-table.noBorders {
	@include noTableBorders();
}

/**
 * these table styles were originally migrated from `prime.scss`
 * in order to mimic the styling of turbo tables (which are editable tables)
 * while using mat-tables (which are read-only tables)
 */
::ng-deep table.prism-table {
	table-layout: unset;
	width: 100%;

	&.noBorders {
		@include noTableBorders();
	}

	thead {
		background-color: getColor('secondaryBackgroundColor');
		tr {
			height: 40px;
			th {
				text-transform: uppercase;
				font-size: 12px;
				letter-spacing: 1px;
				border-top: 1px solid getColor('borderColor');
				// these are !important to override our primeng theme
				background-color: getColor('alwaysPrimaryDark') !important;
				color: getColor('alwaysLightGray') !important;
				font-weight: 300 !important;
				padding: 6px !important;

				&:first-of-type {
					padding-left: 8px;
					border-left: 1px solid getColor('borderColor');
				}

				&:last-of-type {
					padding-right: 8px;
					border-right: 1px solid getColor('borderColor');
				}
			}
		}
	}
	tbody {
		background-color: transparent;
		color: getColor('fontColor');

		tr {
			border: 0;

			td {
				border: 0;

				// override primeng styles
				padding: 8px !important;

				&.status_text span,
				&.created_at span {
					padding: 7px;
					border-radius: 3px;
					font-size: 12px;
					margin: 0 auto;
				}

				input {
					outline: none !important;
				}

				&:first-of-type {
					padding-left: 8px;
				}

				&:last-of-type {
					padding-right: 8px;
				}
			}
		}
	}
	tbody > tr > td {
		border-bottom: 1px solid getColor('borderColor') !important;
		border-right: 1px solid getColor('borderColor');
		&:first-child {
			border-left: 1px solid getColor('borderColor');
		}
	}

	tfoot td {
		padding: 8px !important;
	}

	td,
	th {
		&.button-cell {
			text-align: center;
		}
	}
}
