@keyframes fadeIn {
	0% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		display: block;
		opacity: 1;
	}
	100% {
		display: none;
		opacity: 0;
	}
}
