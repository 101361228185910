.stage-dot {
	height: 12px;
	width: 12px;
	min-width: 12px;
	border-radius: 50%;
	border: 1px solid getColor('secondaryFontColor');
	display: inline-block;
}
.stage-dot:not(.no-margin) {
	margin-right: spacingLevel(2);
}
