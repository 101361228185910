// the only place we should import colors directly
@import 'colors';
////////////////////////////////////////////////////////////////////////////////
// BORDERS
////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////
// SHADOWS
////////////////////////////////////////////////////////////////////////////////

// TODO PRSM-10620 consolidate

// logo preview
$logoPreviewShadow: inset 0px 0 5px 1px getColor('logoPreviewShadow');
// venue form & table
$venueFormStageShadow: 0 0 2px 1px getColor('dropShadowColor');
// box shadows
$bottomBoxShadow: 0px 4px 6px getColor('dropShadowColor'), 0px 2px 4px getColor('dropShadowColor'),
	0px 1px 2px getColor('dropShadowColor');
$topBoxShadow: 0px -4px 6px getColor('dropShadowColor'), 0px -2px 4px getColor('dropShadowColor'),
	0px -1px 2px getColor('dropShadowColor');
$overlayBoxShadow: 0px 1px 16px getColor('dropShadowColor'), 0px 6px 12px getColor('dropShadowColor'),
	0px 4px 8px getColor('dropShadowColor');
$sidenaveToggleShadow: 0 0 6px 0px getColor('dropShadowColor');
$sidepanelShadow: -2px 0px 5px 0px getColor('dropShadowColor');
// shared event
$sharedEventHoldsWarningShadow: 0px 1px 16px getColor('dropShadowColor'), 0px 6px 12px getColor('dropShadowColor'),
	0px 4px 8px getColor('dropShadowColor');
// files form
$fileDropTargetShadow: inset 0 0 9px 0px getColor('dropShadowColor');
// calendar page & header;
$calendarHoldDetailShadow: 0 10px 20px getColor('dropShadowColor'), 0 6px 6px getColor('dropShadowColor');
// events list
$eventListContainerShadow: 0 1px 8px getColor('dropShadowColor'), 0 2px 4px getColor('dropShadowColor'),
	0 1px 2px getColor('dropShadowColor');
// simple form field
$simpleFormFieldSuggestionShadow: 0px 2px 4px -1px getColor('dropShadowColor'),
	0px 4px 5px 0px getColor('dropShadowColor'), 0px 1px 10px 0px getColor('dropShadowColor');
// simple table
$simpleTableDragShadow: 0 5px 5px -3px getColor('dropShadowColor'), 0 8px 10px 1px getColor('dropShadowColor'),
	0 3px 14px 2px getColor('dropShadowColor');
// markdown input
$markdownInputEmsDataPopupShadow: 0 2px 4px 0 getColor('dropShadowColor');
// columns set
$columnsSetRowDragShadow: 0px 0px 9px getColor('dropShadowColor');
