@use '@angular/material' as mat;
@use 'sass:map';
@import 'palette';
@include mat.core();

/*
 * This file defines the color palettes and themes used throughout the application.
 * It includes:
 * - Importing Angular Material theming utilities and custom palettes.
 * - Defining primary, accent, and warn palettes.
 * - Creating light and dark themes.
 * - Defining individual color variables used in the application.
 * - Assembling the `$colors` map, which maps color names to their corresponding values and theme variants.
 * - Utility functions:
 *   - `_getSassColor($name, $theme)` - Retrieves the raw Sass color value from the `$colors` map.
 *   - `getColor($name)` - Retrieves a CSS variable reference with a fallback value.
 * - Mixin:
 *   - `@mixin setThemeVariables($theme)` - Generates CSS custom properties for all colors for a specific theme.
 *
 * The `$colors` map is crucial to our UI as it centralizes all color definitions,
 * allowing for easy theming and maintenance across the application.
 */

// Both primary and accent are set to the same color pallete
// We expect no color changes between them over angular material
// components
$app-primary: mat.define-palette($prism-primary, 700);
$app-accent: mat.define-palette($prism-primary, 500);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette($prism-error);

// Create the theme object (a Sass map containing all of the palettes).
$light-theme: mat.define-light-theme(
	(
		color: (
			primary: $app-primary,
			accent: $app-primary,
			warning: $app-warn,
		),
	)
);

$dark-theme: mat.define-dark-theme(
	(
		color: (
			primary: $app-accent,
			accent: $app-accent,
			warning: $app-warn,
		),
	)
);

// TODO PRSM-10547 pare this list down
// CONVENTION: Prefix all variables with $_ to indicate that they are not intended to be referenced outside of this file
$_focusedBackgroundColor: rgba(156, 39, 176, 0.1);
$_focusedBackgroundColorDarkTheme: rgba(139, 105, 202, 40%);
$_hoverBackgroundColor: lighten($_focusedBackgroundColor, 27%);
$_backgroundGray: #f9f9f9;
$_whiteDark: #1d1b1e;
$_secondaryTextGray: #636363;
$_white: #ffffff;
$_prismPurple: mat.get-color-from-palette($prism-primary, 600);
$_hoverBackgroundGray: mat.get-color-from-palette($prism-gray, 100);
$_sevenfive: #757575;
$_panelBackground: #f4f4f4;
$_warningYellow: #fff8eb;
$_warningYellowText: mat.get-color-from-palette($prism-warning, 700);
$_warningYellowBackground: mat.get-color-from-palette($prism-warning, 50);
$_lightBorderColor: #e9e9e9;
$_secondaryDark: #535353;
$_black: #000000;
$_iconGray: #939393;
$_indicatorRed: mat.get-color-from-palette($prism-error, 700);
$_darkThemeSecondaryFontColor: map-get($dark-theme, foreground, secondary-text);
$_primaryDark: #282828;
$_prismLightBlue: #64a0e7;
$_error: mat.get-color-from-palette($prism-error, 500);
// Can we merge these?
$_accentGreen: #65b669;
$_indicatorGreen: #43a047;

// TODO PRSM-10547 we have LOTS of context-specific colors declared here. Move to generic colors that we can reuse
// TODO PRSM-10547 clarify/generecize color names to promote reuse and consolidate where possible
// TODO PRSM-10548 move away from hard coded hex values in favor of mat.get-color-from-palette() function calls
// TODO PRSM-10548 map all these variables with Prism colors from the available palettes base by using the mat-mat.get-color-from-palette(() function
/**
 * Map of all the colors used in the application UI.
 *
 * Each color entry can be a single value or a map containing theme variants (`light` and `dark`).
 * This centralizes all color definitions, making it easier to manage and maintain consistency across the UI.
 * access in components with getColor('colorName'), or in rare occassions, _getSassColor('colorName', 'theme')
 * to apply all variables for a specified theme, use the setThemeVariables('theme') mixin
 */

$colors: (
	prismPurple: $_prismPurple,
	prismPurpleSlightlyDarkened: darken($_prismPurple, 3%),
	selectedGray: #d4d4d4,
	accentPurple: (
		light: $_prismPurple,
		dark: mat.get-color-from-palette($prism-violet, 300),
	),
	fontColor: (
		light: $_whiteDark,
		dark: $_white,
	),
	iconGray: $_iconGray,
	secondaryFontColor: (
		light: $_secondaryDark,
		dark: $_darkThemeSecondaryFontColor,
	),
	secondaryBackgroundColor: (
		light: $_backgroundGray,
		dark: $_whiteDark,
	),
	primaryDark: (
		light: $_primaryDark,
		dark: $_white,
	),
	backgroundColor: (
		light: $_white,
		dark: #262525,
	),
	// this is for hover states in dropdown menus
	// this should always be lighter than focusedBackground
	hoverBackground:
		(
			light: $_hoverBackgroundColor,
			dark: lighten($_focusedBackgroundColorDarkTheme, 10%),
		),
	// this is for selected states in dropdown menus, and focused states on inputs
	// this should always be darker than hoverBackground
	focusedBackground:
		(
			light: $_focusedBackgroundColor,
			dark: $_focusedBackgroundColorDarkTheme,
		),
	linkBlue: (
		light: #01579b,
		dark: #78c4ff,
	),
	focusedBackgroundBorder: darken($_focusedBackgroundColor, 10%),
	borderColor: (
		light: #e3e3e3,
		dark: $_backgroundGray,
	),
	secondaryBorderColor: (
		light: #e9e9e9,
		dark: $_secondaryTextGray,
	),
	secondaryTextGray: (
		light: $_secondaryTextGray,
		dark: $_white,
	),
	badgeBorderColor: (
		light: mat.get-color-from-palette($prism-primary, 200),
		dark: mat.get-color-from-palette($prism-primary, 500),
	),
	badgeTextColor: (
		light: $_prismPurple,
		dark: mat.get-color-from-palette($prism-primary, 300),
	),
	badgeBackgroundColor: (
		light: mat.get-color-from-palette($prism-primary, 50),
		dark: mat.get-color-from-palette($prism-primary, 950),
	),
	// this is for simple tables
	backgroundGray:
		(
			light: $_backgroundGray,
			dark: #424242,
		),
	alwaysLightGray: $_backgroundGray,
	alwaysWhite: $_white,
	alwaysBlack: $_black,
	alwaysPrimaryDark: $_primaryDark,
	alwaysSecondaryDark: $_secondaryDark,
	// this is for simple tables
	hoverBackgroundGray:
		(
			light: $_hoverBackgroundGray,
			dark: $_sevenfive,
		),
	// this is for simple tables
	panelBackground:
		(
			light: $_panelBackground,
			dark: $_sevenfive,
		),
	warningYellow: (
		light: $_warningYellow,
		dark: #4e1d09,
	),
	warningYellowText: $_warningYellowText,
	warningYellowBackground: $_warningYellowBackground,
	warningYellowBorder: (
		light: darken($_warningYellow, 10%),
		dark: #8a3816,
	),
	lightBorderColor: $_lightBorderColor,
	indicatorRed: $_indicatorRed,
	reportPageHeaderTextColor: (
		light: mat.get-color-from-palette($prism-gray, 900),
		dark: $_secondaryDark,
	),
	reportPageExplainerTextColor: (
		light: mat.get-color-from-palette($prism-gray, 600),
		dark: $_darkThemeSecondaryFontColor,
	),
	rentalFeeInfoBackground: (
		light: #fcdd80,
		dark: mat.get-color-from-palette($prism-warning, 950),
	),
	rentalFeeInfoColor: (
		light: #7a6525,
		dark: #fcc549,
	),
	dangerButtonOutlineBackground: (
		light: transparent,
		dark: mat.get-color-from-palette($prism-error, 950),
	),
	dangerButtonOutlineFontColor: (
		light: $_indicatorRed,
		dark: mat.get-color-from-palette($prism-error, 300),
	),
	// TODO PRSM-10547 wtf is this and can it be removed or merged with the other greens?
	stageSelectColorBackground: #51bc51,
	// TODO PRSM-10547 can this be merged with indicatorGreen?
	accentGreen: $_accentGreen,
	indicatorGreen: $_indicatorGreen,
	chipBackgroundColor: #e9e9e9,
	disabledGray: darken($_backgroundGray, 20%),
	accentBlue: mat.get-color-from-palette($prism-blue, 500),
	error: $_error,
	eventCardGradientTop: #cde6fd,
	eventCardGradientBottom: #ecd7fb,

	// event-status and mixins function
	eventStatusHoldColor: #eaf3d9,
	eventStatusConfirmedColor: $_accentGreen,
	eventStatusSettlementColor: #e1ecfa,
	eventStatusSettledColor: $_prismLightBlue,
	eventStatusArchivedColor: #595959,

	// TODO PRSM-10547 I think we can probably deprecate this
	prismLightBlue: $_prismLightBlue,

	// TODO PRSM-10547 only used for impersonation–wouldn't scale to other colors for other environments
	impersonateActiveMenuItemBackground:
		(
			light: mat.get-color-from-palette($prism-error, 600),
			dark: mat.get-color-from-palette($prism-error, 700),
		),
	impersonateActiveMenuItemColor: mat.get-color-from-palette($prism-error, 50),
	impersonateProdActiveMenuItemBackgroundHover: mat.get-color-from-palette($prism-error, 800),
	impersonateProdBackground: (
		light: mat.get-color-from-palette($prism-error, 50),
		dark: mat.get-color-from-palette($prism-error, 900),
	),
	impersonateProdBorderColor: (
		light: mat.get-color-from-palette($prism-error, 300),
		dark: mat.get-color-from-palette($prism-error, 200),
	),
	impersonateProdIconColor: (
		light: mat.get-color-from-palette($prism-error, 700),
		dark: mat.get-color-from-palette($prism-error, 100),
	),
	impersonateProdMenuItemBackgroundHover: (
		light: mat.get-color-from-palette($prism-error, 200),
		dark: mat.get-color-from-palette($prism-error, 500),
	),

	// TODO PRSM-10547 Only referenced by shared-event-badge.component.scss
	lightBackground: #ede4f1,

	// TODO PRSM-10547 only used for offer preview (an admin only component), delete and pick different colors to use
	offerDescriptionColor: #8c8c8c,
	// TODO PRSM-10547 only used for offer preview (an admin only component), delete and pick different colors to use
	offerPreviewBorder: getColor('secondaryBackgroundColor'),
	// TODO PRSM-10547 can we consolidate with some of the shadow and overlay colors?
	// event status select
	eventStatusItemDisabledColor: rgba(0, 0, 0, 0.38),
	eventStatusSpinnerBackground: rgba(200, 200, 200, 0.6),
	// LEVEL INDICATORS
	// default
	defaultLevelBackground: mat.get-color-from-palette($prism-gray, 100),
	defaultLevelColor: mat.get-color-from-palette($prism-gray, 700),
	defaultLevelBorder: mat.get-color-from-palette($prism-gray, 500),
	// info
	infoLevelBackground: mat.get-color-from-palette($prism-blue-light, 50),
	infoLevelColor: mat.get-color-from-palette($prism-blue-light, 700),
	infoLevelBorder: mat.get-color-from-palette($prism-blue-light, 500),
	// error
	errorLevelBackground: mat.get-color-from-palette($prism-error, 50),
	errorLevelColor: mat.get-color-from-palette($prism-error, 700),
	errorLevelBorder: $_error,
	// success
	successLevelBackground: mat.get-color-from-palette($prism-success, 50),
	successLevelColor: mat.get-color-from-palette($prism-success, 700),
	successLevelBorder: mat.get-color-from-palette($prism-success, 500),
	// warning
	warningLevelBackground: mat.get-color-from-palette($prism-warning, 50),
	warningLevelColor: mat.get-color-from-palette($prism-warning, 700),
	warningLevelBorder: mat.get-color-from-palette($prism-warning, 500),
	// Chat GPT
	chatGPTSystemPrompt: #eb8714,
	chatGPTUserPrompt: #006fc3,

	// health status bar
	healthStatusBarBad: #f47265,
	healthStatusBarOk: #fff176,
	healthStatusBarOkText: #f57f17,
	healthStatusBarGood: $_indicatorGreen,
	healthStatusBarBackground: #d2d2d2,

	// event settings
	inactiveHoldButtonColor: mat.get-color-from-palette($prism-gray-true, 400),
	inactiveHoldButtonBackground: mat.get-color-from-palette($prism-gray-neutral, 100),
	// manage holds colors
	activeHoldBackground: #ffff96,
	confirmEventUnclearableBackground: #f2dede,
	confirmEventUnclearableColor: #a94442,

	// Shadow and Overlay colors, allowed to have alpha
	logoPreviewShadow: rgba(208, 208, 208, 0.54),
	dropShadowColor: rgba(0, 0, 0, 0.1),
	buttonBackgroundSelected: rgba(40, 40, 40, 0.2),
	scrollDropBorder: #e6e6e6,

	//  TODO PRSM-10547 can we consolidate with hoverBackground or focusedBackground?
	darkOverlayBackground: rgba(0, 0, 0, 0.318),
);

/**
 * The inteiton is that ultimately, we should either (1) never need to use this helper externally, or (2)
 * use it only in cases where we would otherwise define and reference an only* color in the $colors map,
 * where the color already exists as a themed color in the the $colors map. If we go the latter route,
 * we should be able to remove all of the only* colors from the $colors map.
 *
 * Retrieves the raw Sass color value from the `$colors` map for a given color name and theme.
 *
 * This function is used to obtain the actual color value (e.g., `#rrggbb` or `rgba(r, g, b, a)`) from the `$colors` map,
 * which can be manipulated using Sass color functions like `darken()` or `lighten()`. This is
 * necessary because CSS variables cannot be used in Sass functions that expect color values
 * at compile time.
 *
 * @param {string} $name - The name of the color to retrieve from the `$colors` map.
 * @param {string} $theme - The theme variant to retrieve ('light' or 'dark'). Defaults to 'light'.
 * @return {color} - The raw Sass color value (e.g., `#rrggbb` or `rgba(r, g, b, a)`).
 *
 * @throws Will throw an error if the color name is not found in the `$colors` map or if the specified
 *         theme variant is not defined for the color.
 *
 * @example
 *   // Assuming $colors map includes:
 *   // $colors: (
 *   //   primaryColor: (
 *   //     light: #ffffff,
 *   //     dark: #000000,
 *   //   ),
 *   //   accentColor: #ff0000,
 *   // );
 *
 *   // Usage:
 *   $primaryColorDark: _getSassColor('primaryColor', 'dark'); // Returns '#000000'
 *   $accentColor: _getSassColor('accentColor'); // Returns '#ff0000'
 */
@function _getSassColor($name, $theme: 'light') {
	// Ensure the color name exists in the $colors map
	@if not map-has-key($colors, $name) {
		@error "Color `#{$name}` not found in \$colors map.";
	}

	$color-value: map-get($colors, $name);

	// Determine if the color value is a map (theme variants) or a single value
	@if (type-of($color-value) == 'map') {
		// Get the color value for the specified theme
		@if not map-has-key($color-value, $theme) {
			@error "Theme `#{$theme}` not defined for color `#{$name}`.";
		}
		@return map-get($color-value, $theme);
	} @else {
		// Return the single color value
		@return $color-value;
	}
}

/**
 * Retrieves a color from the `$colors` map and returns a CSS variable reference with a fallback value.
 *
 * This function is designed to fetch a color value by its name from the `$colors` map. The returned value
 * is a CSS variable reference (e.g., `var(--color-name)`) with a fallback to the light theme color or
 * a specified default. This allows for dynamic theming using CSS variables while ensuring a fallback
 * for environments where CSS variables may not be supported.
 *
 * @param {string} $name - The name of the color to retrieve from the `$colors` map.
 * @return {string} - A CSS variable function call for the color (e.g., `var(--color-name, #fallback)`).
 *                    If the color is theme-specific, the fallback is the 'light' theme color.
 *
 * @throws Will throw an error if the color name is not found in the `$colors` map or if the light theme
 *         color is not defined for a theme-specific color.
 *
 * @example
 *   // Assuming $colors map includes:
 *   // $colors: (
 *   //   primaryColor: (
 *   //     light: #ffffff,
 *   //     dark: #000000,
 *   //   ),
 *   //   accentColor: #ff0000,
 *   // );
 *
 *   // Usage:
 *   .element {
 *     color: getColor('primaryColor'); // Returns 'var(--primaryColor, #ffffff)'
 *     background-color: getColor('accentColor'); // Returns 'var(--accentColor, #ff0000)'
 *   }
 */
@function getColor($name) {
	// Ensure the color name exists in the $colors map
	@if not map-has-key($colors, $name) {
		@error "Color `#{$name}` not found in \$colors map.";
	}

	// Use _getSassColor to get the fallback value from the light theme
	$fallback: _getSassColor($name, 'light');

	// Return the CSS variable with fallback
	@return var(--#{$name}, #{$fallback});
}

/**
 * Mixin to generate CSS custom properties (variables) for all colors defined in the `$colors` map for a specific theme.
 *
 * This mixin iterates over all the colors in the `$colors` map and sets CSS custom properties (variables)
 * for each color, assigning the value corresponding to the specified theme. This allows for dynamic theming
 * by switching the values of CSS variables based on the theme.
 *
 * @param {string} $theme - The theme name to set the colors for ('light' or 'dark').
 *
 * @example
 *   // Assuming $colors map includes:
 *   // $colors: (
 *   //   primaryColor: (
 *   //     light: #ffffff,
 *   //     dark: #000000,
 *   //   ),
 *   //   accentColor: #ff0000,
 *   // );
 *
 *   // Usage:
 *   .light-theme {
 *     @include theme-variables('light');
 *   }
 *
 *   .dark-theme {
 *     @include theme-variables('dark');
 *   }
 *
 *   // Resulting CSS:
 *   // .light-theme {
 *   //   --primaryColor: #ffffff;
 *   //   --accentColor: #ff0000;
 *   //   // ...
 *   // }
 *   // .dark-theme {
 *   //   --primaryColor: #000000;
 *   //   --accentColor: #ff0000;
 *   //   // ...
 *   // }
 */
@mixin setThemeVariables($theme) {
	@each $name, $color-value in $colors {
		// Use _getSassColor to retrieve the theme-specific color value
		@if type-of($color-value) == 'map' {
			@if map-has-key($color-value, $theme) {
				$theme-color: _getSassColor($name, $theme);
				--#{$name}: #{$theme-color};
			} @else {
				@warn "Theme `#{$theme}` not defined for color `#{$name}`.";
			}
		} @else {
			// Use the single color value for all themes
			--#{$name}: #{$color-value};
		}
	}
}
