@import './colors';
@import './vars';
@import './position';
/**
 UI elements
 */
@mixin button() {
	color: getColor('primaryDark');
	font-weight: normal;
	letter-spacing: 0.25px;
	padding-top: 2px;
	padding-bottom: 2px;
	border-color: getColor('borderColor');

	&.selected {
		background-color: getColor('selectedGray');
		border-color: transparent;
	}
}

@mixin buttonWithIcon() {
	@include button();
	mat-icon {
		color: getColor('iconGray');
	}
}

@mixin simulateMatFocused() {
	.mat-mdc-button-persistent-ripple {
		opacity: 0.1;
	}
}

@mixin primaryButton() {
	appearance: none;
	border: 0;
	height: 40px !important;
	min-width: 120px;
	text-align: center;
	border-radius: 8px;
	opacity: 1;
	transition: opacity 300ms;
	border: 1px solid transparent;
	background: getColor('prismPurple') !important;
	color: getColor('alwaysWhite') !important;
	position: relative;
	cursor: pointer;

	&[disabled],
	&.visually-disabled {
		cursor: initial;
		color: getColor('backgroundColor');
		opacity: 0.3;
	}
}

@mixin secondaryButton() {
	@include primaryButton();
	color: getColor('accentPurple') !important;
	background-color: getColor('secondaryBackgroundColor') !important;
	border-color: getColor('secondaryBorderColor') !important;
}

@mixin greenPrimaryButton() {
	@include primaryButton();
	color: getColor('alwaysWhite') !important;
	background-color: getColor('accentGreen') !important;
	// border-color: darken(_getSassColor('secondaryBorderColor'), 5%) !important;
}

@mixin neutralButton() {
	@include secondaryButton();
	color: getColor('secondaryFontColor') !important;
}

@mixin dangerButton() {
	@include primaryButton();
	color: getColor('alwaysWhite') !important;
	background-color: getColor('indicatorRed') !important;
}

@mixin warningButton() {
	@include primaryButton();
	background-color: getColor('warningYellowBackground') !important;
	border: 1px solid getColor('warningYellowBorder') !important;
	color: getColor('warningYellowText') !important;
}

@mixin customButton() {
	@include secondaryButton();
	background-color: var(--button-background-color) !important;
	border: 1px solid var(--button-border-color) !important;
	color: var(--button-color) !important;
}

@mixin dangerButtonOutline() {
	@include primaryButton();
	color: getColor('dangerButtonOutlineFontColor') !important;
	background-color: getColor('dangerButtonOutlineBackground') !important;
	border-color: getColor('dangerButtonOutlineFontColor') !important;
}

@mixin primaryButtonOutline() {
	@include primaryButton();
	color: getColor('prismPurple') !important;
	background-color: getColor('badgeBackgroundColor') !important;
	border-color: transparent !important;
}

@mixin smallButton() {
	border: 1px solid getColor('secondaryBorderColor');
	border-radius: 8px;
	background-color: getColor('backgroundColor');
	transition: background-color 300ms, border-color 300ms, opacity 300ms;
	padding: 0 spacingLevel(2);
	font-weight: normal;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	height: 40px !important;
	// if we decide to actually make these smaller:
	// height: 30px !important;
	// font-size: 12px;

	mat-icon {
		color: getColor('iconGray');
		position: relative;
	}

	&.selected,
	&[selected] {
		::ng-deep {
			@include simulateMatFocused();
		}
	}

	&:not(button) {
		cursor: pointer;
		&:hover {
			.mat-mdc-button-persistent-ripple {
				opacity: 0.1;
			}
		}
	}
}

@mixin circleButton() {
	border: 2px solid getColor('borderColor');
	border-radius: 50%;
	width: 36px;
	height: 36px;
	margin: 2px;
	@include flex-row(center);
	font-size: 14px;
	background-color: transparent;
	color: getColor('fontColor');
	transition: background-color 250ms;
	cursor: pointer;

	&:hover {
		&:not([disabled]) {
			background-color: getColor('hoverBackground');
		}
	}

	&:not(.selected) {
		&[disabled],
		&.disabled {
			opacity: 0.3;
		}
	}

	&.selected {
		border-color: getColor('prismPurple');
		background-color: getColor('prismPurple');
		color: getColor('alwaysWhite');
	}
}

button.circle-button {
	appearance: none;
	@include circleButton();
}

@mixin pageHeaderContainer() {
	background-color: getColor('backgroundColor');
	box-shadow: $bottomBoxShadow;

	@include desktopOnly() {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		left: $menuBarHeight;
		@include z('desktopHeaderZIndex');
	}

	@include mobileOnly() {
		@include z('mobileHeaderZIndex');
		position: relative;
	}

	.page-header {
		height: $pageHeaderHeight;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 10px 20px;
		justify-content: space-between;
		margin: auto 0;
	}

	.projected-page-header {
		height: auto;
		min-height: $secondaryPageHeaderHeight;
		border-top: 1px solid getColor('secondaryBorderColor');
	}

	.page-header {
		.filter-toggle-button {
			@include smallButton();
		}

		.page-container-page-title {
			font-size: 20px;
			margin: 0;
		}
	}

	&:not(.has-second-page-header) .projected-page-header {
		display: none;
	}
}

@mixin commonPageContainerMixin() {
	::ng-deep {
		.mat-mdc-tab-nav-bar {
			box-shadow: none !important;
			margin-bottom: 0px !important;
		}
	}
	.page-header-container {
		@include pageHeaderContainer();
	}
	.page-container {
		padding: 20px;
		@include mobileOnly() {
			padding-left: 5px;
			padding-right: 5px;
		}
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		margin: 0 auto;

		@include desktopOnly() {
			--top-of-screen-offset: #{$pageHeaderHeight};
			padding-top: $pageHeaderHeight * 1.5;
			&.has-nav {
				--top-of-screen-offset: #{$totalPageHeaderHeight};
				padding-top: $pageHeaderHeight * 2.5;
			}
		}
	}
	.header-nav {
		border-top: 1px solid getColor('secondaryBorderColor');
	}
}

@mixin buttonRow() {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: 12px;
}

@mixin truncate() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin labelPill() {
	color: getColor('fontColor');
	font-size: 12px;
	line-height: 14px;
	border-radius: 32px;
	padding: spacingLevel(1) spacingLevel(2);
}

@mixin scrollDropShadowTop() {
	border-top: 1px solid getColor('scrollDropBorder');
	box-shadow: 0px -2px 10px -8px getColor('alwaysBlack');
}

@mixin scrollDropShadowBottom() {
	border-bottom: 1px solid getColor('scrollDropBorder');
	box-shadow: 0px -2px 10px -3px getColor('alwaysBlack');
}

@mixin mobileOnly() {
	@media screen and (max-width: $breakpoint-md) {
		@content;
	}
}

@mixin containerLargerThanTinyScreens() {
	@media screen and (min-width: $breakpoint-sm) {
		@content;
	}
}

@mixin tinyScreens() {
	@media only screen and (max-width: $breakpoint-sm) {
		@content;
	}
}

@mixin desktopOnly() {
	@media screen and (min-width: ($breakpoint-md + 1)) {
		@content;
	}
}

@mixin lgScreensAndUp() {
	@media screen and (min-width: ($breakpoint-lg + 1)) {
		@content;
	}
}

@mixin xlScreensAndUp() {
	@media screen and (min-width: ($breakpoint-xl + 1)) {
		@content;
	}
}

@mixin hugeScreensAndUp() {
	@media screen and (min-width: ($breakpoint-huge + 1)) {
		@content;
	}
}

@mixin colorMarkerStyles($display: block) {
	align-self: center;

	&:after {
		display: $display;
		content: '';
		width: 10px;
		height: 10px;
		border-radius: 5px;
	}

	&.color-marker-hold:after {
		@include eventStatusIndicatorColors(getColor('eventStatusHoldColor'));
	}

	&.color-marker-confirmed:after {
		@include eventStatusIndicatorColors(getColor('eventStatusConfirmedColor'));
	}

	&.color-marker-settlement:after {
		@include eventStatusIndicatorColors(getColor('eventStatusSettlementColor'));
	}

	&.color-marker-settled:after {
		@include eventStatusIndicatorColors(getColor('eventStatusSettledColor'));
	}
	&.color-marker-archived:after {
		@include eventStatusIndicatorColors(getColor('eventStatusArchivedColor'));
	}
}

@mixin overrideSimpleFormFieldStyles($forCell: false) {
	::ng-deep {
		simple-form {
			.for-field--#{$forCell} {
				@content;
			}
		}
	}
}

@mixin smallCircleAvatar() {
	width: 24px;
	height: 24px;
	background-size: contain;
	background-position: center;
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
	border: 1px solid getColor('fontColor');
	background-color: getColor('fontColor');
}

@mixin spacedUppercase() {
	letter-spacing: 1.5px;
	text-transform: uppercase;
}

@mixin noMarginMatFormField() {
	mat-form-field {
		.mat-mdc-form-field-infix {
			min-width: 120px !important;
		}

		.mat-mdc-floating-label {
			display: none;
		}

		.mat-mdc-text-field-wrapper {
			border-radius: 0;
		}

		.mat-mdc-form-field-infix,
		.mat-mdc-form-field-icon-suffix,
		.mat-mdc-form-field-icon-prefix {
			top: 0 !important;
			border-radius: 0 !important;
			border: none !important;
			transition: background-color 300ms !important;
		}

		.mat-mdc-form-field-infix {
			margin-top: 0 !important;
		}
	}
}

/* USAGE (.prism-badge.my-badge-class required for specificity)
 *.prism-badge.live-data-badge {
 *	@include overridePrismBadgeStyles(getColor('indicatorRed'), getColor('alwaysWhite')) {
 *		margin-left: 10px;
 *		margin-top: 2px;
 *	}
 *}
 */
@mixin overridePrismBadgeStyles($textAndBorderColor, $backgroundColor) {
	--badge-text-color: #{$textAndBorderColor};
	--badge-border-color: #{$textAndBorderColor};
	--badge-background-color: #{$backgroundColor};
	@content;
}

@mixin eventStatusIndicatorColors($color) {
	background: $color;
	// TODO PRSM-10548 can we somehow accept a color from getColor and darken it? How would that work with light/dark theming? Right now getColor return var() and darken doesn't like that. Might we need a mixin that can switch on the body.dark-theme class _within_ components for cases like this? (I think angular handles this with pallettes and things like @include mat.all-component-themes($light-theme);)
	border: 1px solid getColor('borderColor');
}

@mixin sideBySideInputs($width) {
	box-sizing: border-box !important;
	display: inline-block !important;
	flex-direction: row !important;
	align-items: center !important;
	width: $width;
}

/**
 * a desktop-only side-by-side container that leverages CSS grid
 *
 * the two grid areas are `title` on the left, and `content` on the right
 */
@mixin sideBySideGrid() {
	@include desktopOnly() {
		display: grid;
		grid-template-columns: 1fr 3fr;
		grid-template-areas: 'title content';
		grid-gap: spacingLevel(7);
	}
}

@mixin clampLines($lines: 1) {
	@include wrapContent();
	display: -webkit-box !important;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@mixin wrapContent() {
	white-space: pre-line;
	width: max-content;
	max-width: 100%;
}

@mixin prismBold() {
	font-weight: 500;
	letter-spacing: 1.5px;
	word-spacing: 0.15px;
}

@mixin prismHeader() {
	font-weight: 500;
	letter-spacing: 0.15px;
	word-spacing: 0.4px;
	font-size: 20px;
}

%chip-container-shared {
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 20px;
	font-size: 12px;
	letter-spacing: 0.25px;
	text-transform: capitalize;
	background-color: getColor('chipBackgroundColor');
	color: getColor('alwaysPrimaryDark');
	padding: 0px 12px;
	position: relative;
}

@mixin simpleTableGridListView() {
	@include simpleTableGridLayout(1, 1, 1, 1, 16px);
}

@mixin simpleTableGridLayout($itemsSm: 1, $itemsMd: 2, $itemsLg: 3, $itemsXl: 4, $itemsGap: 16px) {
	--grid-item-sm: #{$itemsSm};
	--grid-item-md: #{$itemsMd};
	--grid-item-lg: #{$itemsLg};
	--grid-item-xl: #{$itemsXl};
	--grid-item-gap: #{$itemsGap};
}

@mixin clearfix {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin dataRangeFilters() {
	.mat-mdc-radio-button {
		display: block;
	}
	.date-choice-secondary {
		margin-left: 10px;
		font-size: 14px;
		color: getColor('secondaryFontColor');
	}
	.event-filters-label {
		@include flex-row(center-between);
		margin-bottom: 10px;

		.include-mad-toggle,
		.only-mad-toggle {
			font-weight: normal;
			font-size: 14px;
			opacity: 1;
			transition: opacity 250ms;

			&.hide {
				opacity: 0;
			}

			::ng-deep label {
				margin-bottom: 0 !important;
			}
		}
	}
	.custom-date-picker {
		display: none;
		padding-left: 25px;

		&.show {
			display: block;
		}
	}
}
